#our_products_section {
  padding: 100px 168px 120px;
}

#our_products_section .title {
  font-size: 38px;
  font-weight: 800;
  margin: 0;
  text-align: center;
}
#our_products_section li h2 {
  text-align: center;
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 940 - 1240 */
@media screen and (min-width: 904px) and (max-width: 1240px) {
  #our_products_section {
    padding: 80px 41px 100px;
  }

  #our_products_section .title {
    font-size: 38px;
    font-weight: 800;
    margin-bottom: 32px;
  }

  #our_products_section li {
    width: 240px;
    height: 240px !important;
  }

  #our_products_section li h2 {
    font-size: 26px !important;
    margin-bottom: 40px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  #our_products_section {
    padding: 80px 176px 116px;
  }

  #our_products_section .title {
    font-size: 45px;
    font-weight: 800;
    margin-bottom: 40px;
  }

  #our_products_section li {
    width: 240px;
    height: 240px !important;
  }

  #our_products_section li h2 {
    font-size: 26px !important;
    margin-bottom: 40px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
}
