.cardOptionAction {
  background: rgb(241, 243, 243);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 312px;
  height: 312px;
  transition: 0.5s ease-out;
  text-transform: uppercase;
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */

@media screen and (min-width: 904px) and (max-width: 1240px) {
  .cardOptionAction {
    background: rgb(241, 243, 243);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: 240px;
    height: 240px;
    transition: 0.5s ease-out;
    text-transform: uppercase;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  .cardOptionAction {
    background: rgb(241, 243, 243);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: 240px;
    height: 240px;
    transition: 0.5s ease-out;
    text-transform: uppercase;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
}
