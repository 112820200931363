.MainSection {
  padding: 112px;
  padding-top: 140px;
}

.MainSection .section1 {
  display: flex;
  align-items: center;
  gap: 136px;
}

.MainSection .image {
  flex-shrink: 0;
  object-fit: contain;
  height: 440px;
  width: 536px;
}

.MainSection .desc {
  width: 424px;
}

.MainSection .desc .title {
  font-weight: 800;
  font-size: 32px;
  line-height: 34px;
}

.MainSection .desc .content-text {
  font-weight: 300;
}

.MainSection .nextSecBtn {
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 80px;
  padding: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */

@media screen and (min-width: 904px) and (max-width: 1240px) {
  .MainSection {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .MainSection .section1 {
    gap: 80px;
  }

  .MainSection .image {
    height: 380px;
    width: 368px;
  }

  .MainSection .desc {
    width: 304px;
    margin-bottom: 60px;
  }

  .MainSection .desc .title {
    font-size: 26px;
    margin-bottom: 18px;
  }

  .MainSection .desc .content-text {
    font-size: 15px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  .MainSection {
    padding-top: 80px;
    padding-right: 44px;
    padding-left: 44px;
    padding-bottom: 80px;
  }

  .MainSection .section1 {
    gap: 113px;
  }

  .MainSection .image {
    height: 504px;
    width: 440px;
  }

  .MainSection .desc {
    width: 415px;
    margin-bottom: 60px;
  }

  .MainSection .desc .title {
    font-size: 32px;
    margin-bottom: 18px;
  }

  .MainSection .desc .content-text {
    font-size: 17px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
  .MainSection {
    padding-top: 140px;
    padding-right: 112px;
    padding-left: 112px;
    padding-bottom: 80px;
  }

  .MainSection .section1 {
    gap: 136px;
  }

  .MainSection .image {
    height: 536px;
    width: 440px;
  }

  .MainSection .desc {
    width: 424px;
    margin-bottom: 0;
  }

  .MainSection .desc .title {
    font-size: 32px;
    margin-bottom: 18px;
  }

  .MainSection .desc .content-text {
    font-size: 17px;
  }
}
