.host {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  height: 50vh;
}

.fileInput {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  color: #000;
  text-align: center;

  /* Default/Heading 3 */
  font-family: Rubik;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 141.176% */
  letter-spacing: 2px;
  text-transform: uppercase;
}

.subLabel {
  color: #000;
  text-align: center;

  /* Default/Body text */
  font-family: Rubik;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 23px; /* 135.294% */
  margin-bottom: 24px;
}

.date {
  margin-top: 10px;
}
