.Myfiles-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 120px;
  width: 1096px;
  margin: 0 auto !important;
  margin-bottom: 100px;
}

.Myfiles-page .header {
  font-weight: 800;
  font-size: 45px;
  font-style: normal;
}

.Myfiles-page-green-header {
  height: 40px;
  width: 100%;
  background: #cde6d2;
  vertical-align: middle;
  display: flex;
  padding-left: 112px;
}

.Myfiles-page-green-header .text {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 40px;
}

.Myfiles-page-green-header .text-admin {
  font-size: 17px;
  font-weight: 300;
  line-height: 40px;
}

.Myfiles-page-green-header img {
  cursor: pointer;
  margin-left: 44px;
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 940 - 1240 */
@media screen and (min-width: 904px) and (max-width: 1240px) {
  .Myfiles-page {
    padding: 60px 11px 120px 40px;
    width: 752px;
    margin: 0px;
  }

  .Myfiles-page .header {
    margin-top: 0;
    margin-bottom: 32px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  .Myfiles-page {
    padding: 80px 44px 120px 44px;
    width: 1032px;
    margin: 0px;
  }

  .Myfiles-page .header {
    margin-top: 0;
    margin-bottom: 40px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
  .Myfiles-page {
    padding: 100px 112px 120px;
    width: 1096px;
    margin: 0px;
  }

  .Myfiles-page .header {
    margin-top: 0;
    margin-bottom: 60px;
  }
}
