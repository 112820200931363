.rs-picker,
.rs-picker-default .rs-picker-toggle,
.rs-picker-toggle-textbox {
  border: unset !important;
}

.rs-picker-menu {
  z-index: 9999999999999 !important;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border: 0px !important;
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  -webkit-box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  box-shadow: unset !important;
  border: 0px !important;
}

.rs-picker-default .rs-picker-toggle {
  padding-top: 10px !important;
  -webkit-box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  box-shadow: unset !important;
  border: 0px !important;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 5px !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black !important;
  font-family: Rubik;
  font-size: 13px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0.02em;
}

.rs-calendar-table-cell-in-range:before {
  background-color: #f1f3f3 !important;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #233e4c !important;
  border-radius: unset !important;
}

/* .rs-calendar-table-cell:hover{
  background-color: #233e4c62;

} */

div.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: inset 0 0 0 1px #233e4c;
  box-shadow: inset 0 0 0 1px #233e4c;
  border-radius: unset;
}

.rs-picker-menu
  .rs-calendar
  div.rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: #233e4c56;
  color: white;
  border-radius: unset;
}

button.rs-btn-link {
  color: #233e4c !important;
  border-radius: unset;
}

button.rs-btn-primary {
  background-color: #233e4c !important;
  border-radius: unset;
}

/* Add User&Edit User  */
.userDateTime .rs-picker {
  width: 100%;
  border-radius: 0%;
  box-shadow: "none";
  border-color: "#DFE3E6" !important;
  border-width: thin;
}
.userDateTime .rs-picker:focus {
  border-color: black !important;
  border-width: thin;
}
.userDateTime .rs-picker:hover {
  border-color: black !important;
  border-style: solid;
  border-width: thin;
}
.userDateTime .rs-picker-toggle:hover {
  border-color: black !important;
  border-style: solid;
  border-width: thin;
}
.userDateTime .rs-picker.rs-picker-focused {
  border-color: black !important;
  border-style: solid;
  border-width: thin;
}
.userDateTime .rs-picker input {
  border: 0px;
  width: 100%;
  border-radius: 0%;
}
.userDateTime .rs-picker-toggle {
  border: 0px;
  width: 100%;
  border-radius: 0%;
}
