.MyFilesTabsList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.MyFilesTabsList .container {
  display: flex;
  justify-content: space-between;
}

.MyFilesTabsList .tabItem {
  width: 175px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: 1px solid #f1f3f3;
}

.MyFilesTabsList .tabItem:hover {
  cursor: pointer;
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 940 - 1240 */
@media screen and (min-width: 904px) and (max-width: 1240px) {
  .MyFilesTabsList .tabItem {
    width: 125px;
    height: 37px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  .MyFilesTabsList .tabItem {
    width: 155px;
    height: 55px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
  .MyFilesTabsList .tabItem {
    width: 202px;
    height: 63px;
  }
}
