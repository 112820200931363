$itemMarginBot: 23px;
.sidebar {
  height: calc(100vh - 64px);
  background-color: #233e4c;
  position: fixed;
  z-index: 999;
  left: 0;
  display: flex;
}

.previewBar {
  width: 60px;
  height: 100%;
  padding: 32px 16px;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
}

.moduleButton {
  width: 28px;
  height: 28px;
  margin-bottom: $itemMarginBot;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}
.companyButton {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.expandedBar {
  width: 300px;
  height: 100%;
  background-color: #2c4652;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  padding: 15px;
  padding-bottom: 40px;
}

.companyName {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  span {
    flex-grow: 1;
    height: auto;
    margin-bottom: 0;
  }
}
.title {
  font-family: Rubik;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  color: white;
  display: flex;
  height: 28px;
  margin-bottom: $itemMarginBot;
  cursor: pointer;
}

.separator {
  background-color: #b0b9c0;
  height: 2px;
  width: 97%;
  margin-top: 11px;
  opacity: 0.1;
}
