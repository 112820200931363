.contactus-container {
  padding-left: 112px;
  padding-right: 112px;
  padding-bottom: 208px;
  padding-top: 120px;
  text-align: center;
}
.mb-0 {
  margin-bottom: 0;
}
.contactus-container .form-container {
  display: flex;
  padding-top: 40px;
}

.contactus-container .form-container .card {
  flex-shrink: 0;
  flex-basis: 45%;
  height: 424px;
  width: 424px;
  max-width: 424px;
  /* object-fit: contain; */
  padding: 0;
  margin-right: 12px;
}

.contactus-container .form-container .form {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
}

.contactus-container
  .form-container
  .form
  .MuiFormControl-root:not(.MuiTextField-root) {
  margin-bottom: 12px;
}

.contactus-container
  .form-container
  .form
  .MuiFormControl-root.MuiFormControl-fluid {
  width: 100%;
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 940 - 1240 */
@media screen and (min-width: 904px) and (max-width: 1240px) {
  .contactus-container {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 103px;
    padding-top: 60px;
  }

  .contactus-container .form-container {
    padding-top: 32px;
  }

  .contactus-container .form-container .card {
    width: 240px;
    height: 360px;
    /* flex-basis: unset;
    object-fit: fill; */
  }

  .contactus-container .form-container .MuiFormControlLabel-root span {
    font-size: 13px;
  }

  .contactus-container .form-container .form {
  }

  .contactus-container h1 {
    font-size: 38px;
    margin-top: 0;
  }
  .contactus-container h4 {
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px; /* 140% */
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 0;
  }

  .contactus-container
    .form-container
    .form
    .MuiFormControl-root:not(.MuiTextField-root) {
  }

  .contactus-container
    .form-container
    .form
    .MuiFormControl-root.MuiFormControl-fluid {
  }

  .contactus-container
    .form-container
    .form
    .MuiOutlinedInput-root:not(.MuiInputBase-multiline) {
    height: 31px;
  }

  .contactus-container .form-container .form .MuiOutlinedInput-input {
    height: unset;
  }
  .contactus-container .form-container .form .btnItem {
    padding-top: 10px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  .contactus-container {
    padding-left: 44px;
    padding-right: 44px;
    padding-bottom: 120px;
    padding-top: 60px;
  }

  .contactus-container .form-container {
  }

  .contactus-container h1 {
    font-size: 45px;
    margin-top: 0;
  }
  .contactus-container h4 {
    font-family: Rubik;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px; /* 140% */
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 0;
  }
  .contactus-container .form-container .card {
    width: 328px;
    height: 430px;
    flex-basis: unset;
    object-fit: fill;
  }

  .contactus-container .form-container .MuiFormControlLabel-root span {
    font-size: 14px;
  }

  .contactus-container .form-container .form {
  }

  .contactus-container
    .form-container
    .form
    .MuiFormControl-root:not(.MuiTextField-root) {
  }

  .contactus-container
    .form-container
    .form
    .MuiFormControl-root.MuiFormControl-fluid {
  }

  .contactus-container
    .form-container
    .form
    .MuiOutlinedInput-root:not(.MuiInputBase-multiline) {
    height: 40px;
  }
  .contactus-container .form-container .form .btnItem {
    padding-top: 12px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
  .contactus-container {
    padding-left: 112px;
    padding-right: 112px;
    padding-bottom: 160px;
    padding-top: 100px;
  }

  .contactus-container h1 {
    margin-top: 0;
  }
  .contactus-container h4 {
    margin-top: 0;
  }
  .contactus-container .form-container {
  }

  .contactus-container .form-container .card {
    width: 424px;
    height: 424px;
    flex-basis: unset;
    object-fit: fill;
  }

  .MuiGrid-item {
    padding-top: 12px !important;
  }

  .contactus-container .form-container .form {
  }

  .contactus-container
    .form-container
    .form
    .MuiFormControl-root:not(.MuiTextField-root) {
  }

  .contactus-container
    .form-container
    .form
    .MuiFormControl-root.MuiFormControl-fluid {
  }
  .contactus-container
    .form-container
    .form
    .MuiOutlinedInput-root:not(.MuiInputBase-multiline) {
    height: 48px;
  }
  .contactus-container .form-container .form .btnItem {
    padding-top: 2px !important;
  }
}
