.transparencyActPage {
  flex-grow: 1;
  margin: auto;
  width: 1000px;
  height: auto;
  padding-bottom: 8px;
  margin-top: 100px;
  margin-bottom: 120px;
}

.transparencyActPage .title {
  font-weight: 800;
  font-size: 45px;
  margin-bottom: 40px;
  text-align: center;
}

.transparencyActPage .container {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 24px;
}
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */

@media screen and (min-width: 904px) and (max-width: 1240px) {
  .transparencyActPage {
    width: 832px;
    padding: 60px 39px 100px 100px;
    margin-top: unset;
    margin-bottom: unset;
  }
  .transparencyActPage .title {
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  .transparencyActPage {
    width: 832px;
    margin-top: unset;
    margin-bottom: unset;
  }
  .transparencyActPage .title {
    font-size: 45px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
  .transparencyActPage {
    width: 1320px;
    padding: 100px 168px 120px;
    margin-top: unset;
    margin-bottom: unset;
  }
  .transparencyActPage .title {
    font-size: 45px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}
