.analysis {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 160px;

  div {
    width: 650px;
    text-align: center;
  }
  .spacing {
    height: 80px;
    width: 100%;
    background-color: #f1f3f3;
  }
  .title {
    margin-top: 100px;
    font-family: Rubik;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px; /* 123.529% */
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .header {
    color: #000;
    text-align: center;
    /* Default/Heading 1 */
    font-family: Abhaya Libre ExtraBold;
    font-size: 45px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .desc {
    color: #000;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 40px;
    font-family: Rubik;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 23px; /* 135.294% */
  }
}

.climateFinishPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 160px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 200px;
  padding-right: 224px;
  padding-left: 224px;
  img {
    width: 72px;
    height: 72px;
    margin-bottom: 40px;
  }
  .stepper {
    margin-top: 42px;
    margin-bottom: 57px;
  }
  button {
    margin: 40px 0;
  }

  .note {
    display: flex;
    padding: 24px 20px;
    align-items: center;
    gap: 12px;
    background-color: #78b78538;
    width: 100%;
    svg {
      color: #78b785;
    }
  }
}
