.dictioanry-page {
  padding: 100px;
}

.dictioanry-page .headers {
  text-align: center;
  margin-bottom: 60px;
}

.dictioanry-page .headers h1 {
  margin-bottom: 0;
}

.dictioanry-page .headers h4 {
  margin-bottom: 0;
}

.dictioanry-page .glossary-item {
  margin-bottom: 24px;
}
.module-switch {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: end;
  left: 206px;
  top: -80px;
  div > div {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 10px;
    cursor: pointer;
    background-color: unset;
    color: #78b785;
  }
}
#simple-menu {
  margin-top: 5px;
  .dictionaryItems {
    width: 150px;
    text-transform: uppercase;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 940 - 1240 */
@media screen and (min-width: 904px) and (max-width: 1240px) {
  .dictioanry-page {
    padding-left: 104px;
    padding-right: 104px;
    padding-top: 60px;
    padding-bottom: 100px;
  }

  .dictioanry-page .headers {
    text-align: center;
    margin-bottom: 32px;
  }

  .dictioanry-page .headers h1 {
    margin: 0;
    font-family: Abhaya Libre ExtraBold;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .dictioanry-page .headers h4 {
    margin-bottom: 0;
  }

  .dictioanry-page .glossary-item {
    margin-bottom: 24px;
  }
  .dictioanry-page .glossary-item .glossary-desc {
    margin: 0;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  .dictioanry-page {
    padding-left: 220px;
    padding-right: 220px;
    padding-top: 80px;
    padding-bottom: 120px;
  }

  .dictioanry-page .headers {
    text-align: center;
    margin-bottom: 40px;
  }

  .dictioanry-page .headers h1 {
    margin: 0;
    font-family: Abhaya Libre ExtraBold;
    font-size: 45px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .dictioanry-page .headers h4 {
    margin-bottom: 0;
  }

  .dictioanry-page .glossary-item {
    margin-bottom: 24px;
  }
  .dictioanry-page .glossary-item .glossary-desc {
    margin: 0;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
  .dictioanry-page {
    padding-left: 224px;
    padding-right: 224px;
    padding-top: 100px;
    padding-bottom: 120px;
  }

  .dictioanry-page .headers {
    text-align: center;
    margin-bottom: 60px;
  }

  .dictioanry-page .headers h1 {
    margin: 0;
    font-family: Abhaya Libre ExtraBold;
    font-size: 45px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .dictioanry-page .headers h4 {
    margin-bottom: 0;
  }

  .dictioanry-page .glossary-item {
    margin-bottom: 24px;
  }
  .dictioanry-page .glossary-item .glossary-desc {
    margin: 0;
  }
}
