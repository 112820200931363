#myFilesBodyContainer {
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  background: #f1f3f3;
  max-height: 552px;
  min-height: 100px;
  padding: 40px;
  width: 100%;
  /* overflow-y: hidden; */
  align-content: flex-start;
  justify-content: space-between;
}

#myFilesBodyContainer .infinite-scroll-component__outerdiv {
  width: 100%;
  flex-grow: 1;
  min-height: 0;
  height: 100%;
  padding-bottom: 50px;

  /* ===== Scrollbar CSS ===== */
  scrollbar-width: auto;
  scrollbar-color: #233e4c #dfe3e6;
}

#myFilesBodyContainer .btnContainer {
  width: 100%;
  justify-content: inherit;
}

#myFilesBodyContainer .infinite-scroll-component__outerdiv::-webkit-scrollbar {
  width: 10px;
}

#myFilesBodyContainer
  .infinite-scroll-component__outerdiv::-webkit-scrollbar-track {
  background: #dfe3e6;
}

#myFilesBodyContainer
  .infinite-scroll-component__outerdiv::-webkit-scrollbar-thumb {
  background-color: #233e4c;
  border-radius: 33px;
  border: 3px solid #e0e3e6;
}

#myFilesBodyContainer .inifnite-scroll {
  align-content: flex-start;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  height: 100%;
  overflow-y: auto;
}

#myFilesBodyContainer .dropzone {
  width: 100%;
}
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 940 - 1240 */
@media screen and (min-width: 904px) and (max-width: 1240px) {
  #myFilesBodyContainer {
    padding: 7px 13px 40px 13px;
    gap: 8px;
  }

  #myFilesBodyContainer .infinite-scroll-component__outerdiv {
    height: 542px;
  }

  #myFilesBodyContainer .inifnite-scroll {
    height: 100% !important;
  }

  #myFilesBodyContainer .btnContainer {
  }

  #myFilesBodyContainer .uploadBtn {
    height: 39px;
    margin-bottom: 0;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  #myFilesBodyContainer {
    padding: 10px 13px 40px 13px;
    gap: 10px;
  }
  #myFilesBodyContainer .uploadBtn {
    margin-bottom: 0;
  }

  #myFilesBodyContainer .infinite-scroll-component__outerdiv {
    height: 542px;
  }

  #myFilesBodyContainer .inifnite-scroll {
    height: 100% !important;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
  #myFilesBodyContainer {
    padding: 10px 13px 40px 13px;
    gap: 10px;
  }
  #myFilesBodyContainer .uploadBtn {
    margin-bottom: 0;
  }

  #myFilesBodyContainer .infinite-scroll-component__outerdiv {
    height: 527px;
  }

  #myFilesBodyContainer .inifnite-scroll {
    height: 100% !important;
  }
}
