.theSteps-header-container {
  margin-top: -14px;
}
.step-header-container {
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.step-header-container .header {
  margin-bottom: 40px;
  font-size: 45px;
}

.step-desc {
  width: 648px;
  text-align: center;
}

.footnote {
  width: 648px;
  height: 30px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #bababa;
}

.stage-section {
  margin-bottom: -12px;
}

.stage-section .exampleBox {
  margin-top: -9px;
}
.stage-section .subtitle {
  margin-top: 23px;
}
.stage-section .exampleBox .exampleBox-text {
  padding: 0 20px;
  margin-bottom: 3px;
}

.tipsBox-text {
  padding: 0 20px;
  margin-bottom: 1px;
}

.navigation-btn {
  display: flex;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    font-family: Rubik;
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 2px;
    text-transform: uppercase;
    user-select: none;
  }
  svg {
    width: 48px;
    height: 48px;
  }
}

.navigation-btns {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-bottom: 40px;
}
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 904 - 1240 */
@media screen and (min-width: 904px) and (max-width: 1240px) {
  .theSteps-header-container {
    padding: 60px 140px !important;
    gap: 35px !important;
  }

  .step-header-container .header {
    margin-bottom: unset;
    margin-top: 0px;
    font-size: 45px;
  }
  .step-header-container svg {
    margin-bottom: 15px;
  }
  .step-desc {
    width: 494px;
  }

  .stage-section {
    padding: 80px 40px !important;
  }
  .stage-section h2 {
    font-size: 26px;
  }
  .panelHeader {
    width: 752px !important;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  .theSteps-header-container {
    padding: 100px 134px 126px 150px !important;
    gap: 43px !important;
  }

  .step-header-container svg {
    margin-bottom: 40px;
  }

  .step-header-container .header {
    margin-bottom: unset;
    margin-top: 0px;
    font-size: 45px;
  }

  .step-desc {
    width: 680px;
  }

  .stage-section {
    padding: 80px 44px !important;
  }
  .stage-section h2 {
    font-size: 32px;
  }
  .panelHeader {
    width: 1032px !important;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
  .theSteps-header-container {
    padding: 100px 224px 126px 224px !important;
    gap: 54px !important;
  }
  .step-header-container svg {
    margin-bottom: 40px;
  }
  .step-header-container .header {
    margin-bottom: unset;
    margin-top: 0px;
    font-size: 45px;
  }
  .step-desc {
    width: 648px;
  }

  .stage-section {
    padding: 100px 112px !important;
  }
  .stage-section h2 {
    font-size: 32px;
  }

  .panelHeader {
    width: 1096px !important;
  }
}
