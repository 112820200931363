.transparencyActSidebar {
  padding: 0 25px 0 0;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  z-index: 9;
  padding-top: 5px;
  margin-top: 40px;
  flex-grow: 1;
}

.title {
  font-family: Rubik;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #b0b9c0;
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.stepNumber {
  border-radius: 50%;
  border: 3px solid;
  min-width: 31px;
  height: 31px;
  text-align: center;
  line-height: 1.2;
  cursor: pointer;
  font-weight: 500;
  justify-content: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  padding-top: 3px;
  margin: 0 !important;
}

.stepLabel {
  cursor: pointer;
  font-size: 17px;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 20px;
  height: 26px;
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
}

.navigationIcon {
  min-width: 31px;
  min-height: 31px;
  font-size: 30px;
  cursor: pointer;
}

.navigationLabels {
  text-transform: capitalize;
}

.stepsLinks {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
}

.navigationLinks {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 904 - 1240 */
@media screen and (min-width: 904px) and (max-width: 1240px) {
  .stepNumber {
    min-width: 24px !important;
    min-height: 24px !important;
    width: 24px !important;
    height: 24px !important;
    border: 2px solid;
    font-size: 10px;
  }

  .stepLabel {
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }

  .navigationLabels {
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }

  .navigationIcon {
    min-width: 24px;
    min-height: 24px;
    font-size: 24px;
    cursor: pointer;
  }
}
