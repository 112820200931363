.flaggedSteps {
  padding: 112px;
  padding-top: 140px;
}
.firstSection {
  display: flex;
  gap: 136px;
  justify-content: center;
}
.imgCard {
  flex-shrink: 0;
  object-fit: fill;
  width: 536px !important;
  height: 440px;
}
.textDesc {
  width: 424px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nextSec {
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 80px;
  padding: 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
  div {
    align-self: center;
    margin-top: 12px;
    width: 44px;
    height: 25px;
    margin-bottom: 84px;
  }
}

.stepsContainer {
  padding: 112px;
  padding-top: 100px;
  padding-bottom: 190px;
}

.stepsSections {
  padding: 0 !important;
  padding-bottom: 119px !important;
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 904 - 1240 */
@media screen and (min-width: 904px) and (max-width: 1240px) {
  .flaggedSteps {
    padding: 40px;
    padding-bottom: 0;
  }
  .imgCard {
    flex-shrink: 0;
    object-fit: fill;
    width: 368px !important;
    height: 368px;
  }
  .firstSection {
    gap: 80px;
  }
  .textDesc {
    width: 304px;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1240 - 1440 */
@media screen and (min-width: 1240px) and (max-width: 1440px) {
  .flaggedSteps {
    padding-top: 80px;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 0;
  }
  .firstSection {
    gap: 111px;
  }
  .textDesc {
    width: 415px !important;
  }
}

/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* ========================================================================================= */
/* 1440+ */
@media (min-width: 1441px) {
}
